import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../App.css';
import { SidebarWithContentSeparator } from './sideBar';
import { Textarea } from '@material-tailwind/react';

function Home() {
	const [name, setName] = useState('');
	const [businessDescription, setBusinessDescription] = useState('');
	const [websiteUrl, setWebsiteUrl] = useState('');
	const [ultimateBusinessVision, setUltimateBusinessVision] = useState('');
	const [specificBusinessProblems, setSpecificBusinessProblems] = useState('');
	const [customerResults, setCustomerResults] = useState('');
	const [productsOrServices, setProductsOrServices] = useState('');
	const [idealClientProfiles, setIdealClientProfiles] = useState('');
	const [leadMagnetsUsed, setLeadMagnetsUsed] = useState('');
	const [mainContentThemes, setMainContentThemes] = useState('');
	const [error, setError] = useState(null);
	const navigate = useNavigate(); // Correctly named the navigate hook

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const data = {
				name,
				business_description: businessDescription,
				website_url: websiteUrl,
				ultimate_business_vision: ultimateBusinessVision,
				specific_business_problems: specificBusinessProblems,
				customer_results: customerResults,
				products_or_services: productsOrServices,
				ideal_client_profiles: idealClientProfiles,
				lead_magnets_used: leadMagnetsUsed,
				main_content_themes: mainContentThemes,
			};
			const response = await fetch('https://glowing-invention-backend-755833753464.us-east1.run.app/api/home', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			});
			const responseData = await response.json();
			const sessionId = responseData.session_id;
			localStorage.setItem('sessionId', sessionId);
			navigate('/baps'); // Correct usage of the navigate function
		} catch (error) {
			console.log(error);
			setError(error.error); // Update error state with error message
		}
	};



	return (
		<>

		<button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" class="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
		<span class="sr-only">Open sidebar</span>
		<svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
		<path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
		</svg>
		</button>

		<aside id="logo-sidebar" class="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
		<div class="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
		<a href="https://rediminds.com/" class="flex items-center ps-2.5 mb-5">
		<img src="https://rediminds.com/wp-content/uploads/2021/10/logo-1.jpg" class="h-6 me-3 sm:h-7" alt="Flowbite Logo" />
		<span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Rediminds</span>
		</a>
		<ul class="space-y-2 font-medium">
		<li>
		<div to="/" class="flex items-center p-2 text-blue-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
		{/* <svg class="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 21">
			<path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z"/>
			<path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z"/>
			</svg> */}
		<span class="ms-3 text-blue "><b>01</b> Start Here </span>
		</div>
		</li>
		<li>
		<div to="/baps" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
		{/* <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
			<path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
			</svg> */}
		<span class="flex-1 ms-3 whitespace-nowrap"><b>02</b> Problems You Can Solve </span>
		</div>
		</li>
		<li>
		<div to="/customer-avatars" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
		{/* <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
			<path d="m17.418 3.623-.018-.008a6.713 6.713 0 0 0-2.4-.569V2h1a1 1 0 1 0 0-2h-2a1 1 0 0 0-1 1v2H9.89A6.977 6.977 0 0 1 12 8v5h-2V8A5 5 0 1 0 0 8v6a1 1 0 0 0 1 1h8v4a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-4h6a1 1 0 0 0 1-1V8a5 5 0 0 0-2.582-4.377ZM6 12H4a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Z"/>
			</svg> */}
		<span class="flex-1 ms-3 whitespace-nowrap"><b>03</b> Ideal Clients To Target</span>
		</div>
		</li>
		<li>
		<div to="/lead-magnets" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
		{/* <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
			<path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z"/>
			</svg> */}
		<span class="flex-1 ms-3 whitespace-nowrap"><b>04</b>Lead Magnet Ideas <br/>to Attract Ideal Clients</span>
		</div>
		</li>
		<li>
		<div to="/ideal-book-recommendation" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
		{/* <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
			<path d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z"/>
			</svg> */} 
		<span class="flex-1 ms-3 whitespace-nowrap"><b>05</b> Proposal for <br/>Your Authority Book</span>
		</div>
		</li>

		<li>
		<div to="/ideal-book" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
		{/*  {/* <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
			<path d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z"/>
			</svg> */}
			<span class="flex-1 ms-3 whitespace-nowrap"><b>06</b> Your Authority book</span>
			</div>
			</li>

			<li>
			<div to="audio-book" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
			{/* <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
				<path d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z"/>
				</svg> */}
			<span class="flex-1 ms-3 whitespace-nowrap"><b>07</b> Your Authority Audiobook</span>
			</div>
			</li>


			<li>
			<div to="/sales-letter" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
			{/* <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
				<path d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z"/>
				</svg> */}
			<span class="flex-1 ms-3 whitespace-nowrap"><b>08</b> Copywriting To <br/> Sell Your Book </span>
			</div>
			</li>
			</ul>


			</div>
			</aside>

			<div class="p-4 sm:ml-64">
			<div class="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
			<div class="  mb-4">
			<div >
			<h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
			Welcome To Your Marketing Magic Journey!
			</h1>
			<p className="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">
			Let's discover how you can leverage your knowledge efficiently!
			</p>

			{error && (
				<div className="error-message">
				<p>{error}</p>
				</div>
			)}

			<form onSubmit={handleSubmit} class="max-w-xl mx-auto ">
			<div class="mb-5  ">
			<label for="user_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Name:</label>
			<input
			type="text"
			id="user_name"
			value={name}
			onChange={(e) => setName(e.target.value)}
			class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"

			required/>
			</div>
			<div class="mb-5">
			<label for="business_description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Describe your business in a few sentences. What do you offer, and who are your primary customers?</label>
			<textarea id="business_description" value={businessDescription}  rows="6" onChange={(e) => setBusinessDescription(e.target.value)} class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" ></textarea>

			</div>
			<div class="mb-5">
			<label for="website_url" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Website URL:</label>
			<textarea id="website_URL" value={websiteUrl} rows="1" onChange={(e) => setWebsiteUrl(e.target.value)} class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" ></textarea>

			</div>
			<div class="mb-5">
			<label for="ultimate_business_vision" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
			What is your ultimate business vision for the next year? What specific goals do you want to achieve?
			</label>
			<textarea id="ultimate_business_vision"
			value={ultimateBusinessVision}
			onChange={(e) => setUltimateBusinessVision(e.target.value)}  rows="6" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" ></textarea>  


			</div>

			<div class="mb-5">
			<label for="specific_business_problems" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">What specific problems do your clients typically face that your business helps solve?</label>
			<textarea id="specific_business_problems"
			value={specificBusinessProblems} rows="6"
			onChange={(e) => setSpecificBusinessProblems(e.target.value)} class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" ></textarea>  


			</div>



			<div class="mb-5">
			<label for="customer_results" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
			What kind of results do your customers typically experience after working with you?</label>
			<textarea id="customer_results"
			value={customerResults} rows="6"
			onChange={(e) => setCustomerResults(e.target.value)} class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" ></textarea>  


			</div>



			<div class="mb-5">
			<label for="products_or_services" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">What are the main products or services you offer, and what are their prices?
			</label>
			<textarea    
			id="products_or_services"
			value={productsOrServices}
			onChange={(e) => setProductsOrServices(e.target.value)} rows="6"
			class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"

			/>
			</div>

			<div class="mb-5">
			<label for="ideal_client_profiles" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">In your opinion/experience who are your ideal clients? Please provide detailed customer profiles if available.</label>
			<textarea    
			type="text"
			id="ideal_client_profiles"
			value={idealClientProfiles}
			rows="6"
			onChange={(e) => setIdealClientProfiles(e.target.value)}
			class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"

			/>
			</div>
			<div class="mb-5">
			<label for="lead_magnets_used" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">What lead magnets have you used or are considering to attract your ideal clients?</label>
			<textarea
			type="text"
			id="lead_magnets_used"
			value={leadMagnetsUsed}
			rows="6"
			onChange={(e) => setLeadMagnetsUsed(e.target.value)}
			class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"

			/>
			</div>
			<div class="mb-5">
			<label for="main_content_themes" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Describe any book or major content pieces you have created or plan to create. What are the main themes or topics?</label>
			<textarea
			type="text"
			id="main_content_themes"
			value={mainContentThemes}
			rows="6"
			onChange={(e) => setMainContentThemes(e.target.value)}
			class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"

			/>
			</div>


			<div class="flex items-center justify-center w-full">
			<label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">Upload a document
			<div class="flex flex-col items-center justify-center pt-5 pb-6">
			<svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
			<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
			</svg>
			<p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
			<p class="text-xs text-gray-500 dark:text-gray-400">Any</p>
			</div>
			<input id="dropzone-file" type="file" class="hidden" />
			</label>


			</div> 
			<div class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="user_avatar_help">A document that could help us better understand your world</div>




			<div className="flex justify-end pr-[10%]">
			<button type="submit" onClick={handleSubmit} class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-gray-900 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
			</div>
			</form>


			</div>


			</div>
			</div>
			</div>

			</>


		);

		}

		export default Home;
